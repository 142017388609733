import { Button, Divider, Flex, Grid, LoadingOverlay } from "@mantine/core";
import { IconPlayerTrackPrev } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { PartnerApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";

type IFormData = {
  registerNo: string | undefined;
  roleId: string | undefined;
  familyName: string | undefined;
  lastName: string | undefined;
  firstName: string | undefined;
  departmentUnitId: string | undefined;
  employeeUnitId: string | undefined;
  roleEnableDate: string | undefined;
  roleDisableDate: string | undefined;
  phone: string | undefined;
  phone2: string | undefined;
  email: string | undefined;
  email2: string | undefined;
  socialFbLink: string | undefined;
};

const schema = yup.object({
  registerNo: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё]{2}[0-9]{8}$/, "Зөвхөн регистр оруулна уу")
    .required("Заавал бөглөнө!"),
  roleId: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  familyName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!"),
  lastName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!"),
  firstName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!"),
  departmentUnitId: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  employeeUnitId: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  roleEnableDate: yup.string().required("Заавал бөглөнө!"),
  roleDisableDate: yup.string().required("Заавал бөглөнө!"),
  phone: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Дугаарын формат буруу байна")
    .required("Заавал бөглөнө!"),
  phone2: yup
    .string()
    .transform((value) => (value ? value : null))
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Дугаарын формат буруу байна")
    .nullable(),
  email: yup.string().email("Email оруулна уу").required("Заавал бөглөнө!"),
  email2: yup.string().email("Email оруулна уу").nullable(),
  socialFbLink: yup
    .string()
    .transform((value) => (value ? value : null))
    .matches(/^http(s*):\/\/(www.)*facebook\.com\/[a-zA-Z0-9.]+$/i, "Facebook url биш байна!")
    .nullable(),
});
export function AdminForm({ setActiveTab }: any) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const [action, setAction] = React.useState<any>([]);
  const [data] = React.useState<IFormData>({
    registerNo: user?.admin?.registerNo,
    roleId: user?.admin?.roleId,
    familyName: user?.admin?.familyName,
    lastName: user?.admin?.lastName,
    firstName: user?.admin?.firstName,
    departmentUnitId: user?.departmentUnit?.name,
    employeeUnitId: user?.employeeUnit?.name,
    roleEnableDate: user?.admin?.roleEnableDate,
    roleDisableDate: user?.admin?.roleDisableDate,
    phone: user?.admin?.phone,
    phone2: user?.admin?.phone2,
    email: user?.admin?.email,
    email2: user?.admin?.email2,
    socialFbLink: user?.admin?.socialFbLink,
  });

  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      await PartnerApi.updateAdmin({
        registerNo: values?.registerNo,
        roleId: values?.roleId,
        familyName: values?.familyName,
        lastName: values?.lastName,
        firstName: values?.firstName,
        departmentUnitId: values?.departmentUnitId,
        employeeUnitId: values?.employeeUnitId,
        roleEnableDate: values?.roleEnableDate,
        roleDisableDate: values?.roleDisableDate,
        phone: values?.phone,
        phone2: values?.phone2,
        email: values?.email,
        email2: values?.email2,
        socialFbLink: values?.socialFbLink,
      });
      message.success("Хэрэглэгч баталгаажлаа");
      setAction([]);
      setLoading(false);
    } catch (error: any) {
      message.error(error.message || "Хүсэлт амжилтгүй!");
      setLoading(false);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <Flex h="100%" sx={{ position: "relative" }} direction="column" justify="space-between" gap="md">
            <LoadingOverlay visible={loading} />
            <Grid gutter="sm">
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ҮНДСЭН МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly name="registerNo" placeholder="Админы регистр №" label="Админы регистр №" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly name="familyName" placeholder="Ажилтны ургийн овог" label="Ажилтны ургийн овог" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly name="lastName" placeholder="Ажилтны эцэг эхийн нэр" label="Ажилтны эцэг эхийн нэр" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly name="firstName" placeholder="Ажилтны өөрийн нэр" label="Ажилтны өөрийн нэр" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly name="departmentUnitId" placeholder="Харьяалах нэгжийн нэр" label="Харьяалах нэгжийн нэр" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly name="employeeUnitId" placeholder="Албан тушаалын нэр" label="Албан тушаалын нэр" />
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ҮНДСЭН МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly name="phone" placeholder="Утасны дугаар №1" label="Утасны дугаар №1" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly nullable name="phone2" placeholder="Утасны дугаар №2" label="Утасны дугаар №2" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly name="email" placeholder="И-мэйл хаяг №1" label="И-мэйл хаяг №1" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly nullable name="email2" placeholder="И-мэйл хаяг №2" label="И-мэйл хаяг №2" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField readOnly nullable name="socialFbLink" placeholder="Facebook хаяг" label="Facebook хаяг" />
              </Grid.Col>
            </Grid>
            <Divider />

            {user?.isPartnerConfirmed ? null : (
              <Flex direction="row" align={"end"} justify="space-between">
                <Flex direction="row" gap="md">
                  {action[0] === "edit" ? null : (
                    <Button variant="outline" onClick={() => setActiveTab("info")} leftSection={<IconPlayerTrackPrev size={20} />}>
                      Буцах
                    </Button>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        );
      }}
    </Form>
  );
}
