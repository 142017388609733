import { ActionIcon, Alert, Avatar, Badge, Box, CloseButton, Collapse, Drawer, Flex, Image, Modal, Tabs, Text, Tooltip } from "@mantine/core";
import { IconBuilding, IconEdit, IconPhoto, IconSpeakerphone } from "@tabler/icons-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusinessApi, GeneralApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { MarketingForm } from "../../components/supplier/marketing-form";
import { SupplierInfoForm } from "../../components/supplier/supplier-form";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { initGeneral } from "../../store/General";
import { dateTimeFormat } from "../../utils/date";
import { message } from "../../utils/message";

interface IBuyerProps {
  type: string;
}

export function Buyer({ type }: IBuyerProps) {
  const breadcrumbs = useBreadcrumb();
  const [preAlert, setPreAlert] = React.useState<boolean>(true);
  const [action, setAction] = React.useState<any>([]);
  const tableRef = React.useRef<any>(null);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState<string>("default");
  const [id, setId] = React.useState<string | null>(null);
  const [imageOpen, setImageOpen] = useState<any[]>([]);
  const { businessStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const dispatch = useDispatch();

  const reloadTable = () => {
    tableRef.current.reload();
  };

  const columns = useHeader({
    businessStatus,
    setImageOpen,
    user,
    onClick: (key, record) => {
      switch (key) {
        case "edit":
          setAction(["DOC_FORM", record]);
          setDrawerOpen(true);
          return;

        default:
          break;
      }
    },
  });

  const onCloseAll = async () => {
    const initRes = await GeneralApi.init();
    dispatch(initGeneral(initRes));
    setDrawerOpen(false);
    setAction([]);
    setActiveTab("default");
  };

  return (
    <PageLayout title="Манай худалдан авалт" subTitle="Манай худалдан авалт" breadcrumb={breadcrumbs}>
      <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
        <Collapse in={preAlert}>
          <Alert
            styles={{ root: { backgroundColor: "#FCDDEC" }, message: { color: "#44566c", fontWeight: 500 } }}
            onClose={() => setPreAlert(false)}
            radius="md"
            color="red"
            withCloseButton
            title="Анхаар!">
            Та DeHUB Network-р ханган нийлүүлэгч бизнесүүдтэй хамтран ажиллахын тулд өөрийн худалдан авах үйл ажиллагааг “Buyer бизнесийн профайл”-д үнэн зөв
            бүртгэн оруулах шаардлагатай.
          </Alert>
        </Collapse>

        <Box
          sx={(theme) => ({
            background: theme.white,
            borderRadius: 8,
            border: `1px solid ${theme.colors.gray[2]}`,
            padding: "16px 20px",
          })}
          w={"100%"}>
          <Table ref={tableRef} name="buyer.table" filters={{ query: "", type: "BUYER" }} columns={columns} loadData={(data) => BusinessApi.list(data!)} />
        </Box>
      </Flex>

      <Drawer
        styles={{ body: { overflowY: "scroll" } }}
        position="right"
        opened={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        withCloseButton={false}
        padding="xl"
        size="70%">
        <PageLayout extra={[<CloseButton onClick={() => onCloseAll()} key={1} />]} title="Худалдан авагч бизнесийн мэдээлэл">
          <Tabs variant="pills" value={activeTab} h="100%">
            <Tabs.List>
              <Tabs.Tab leftSection={<IconBuilding color={activeTab === "default" ? "white" : "gray"} />} value="default">
                <Text c={activeTab === "default" ? "" : "gray"}>Үндсэн мэдээлэл</Text>
              </Tabs.Tab>
              <Tabs.Tab disabled={!action[1] && !id} leftSection={<IconSpeakerphone color={activeTab === "marketing" ? "white" : "gray"} />} value="marketing">
                <Text c={activeTab === "marketing" ? "" : "gray"}>Маркетингийн мэдээлэл</Text>
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="default">
              <SupplierInfoForm
                setId={setId}
                type={"BUYER"}
                onClose={() => onCloseAll()}
                reload={reloadTable}
                actionOutside={action}
                setActiveTab={setActiveTab}
              />
            </Tabs.Panel>
            <Tabs.Panel value="marketing">
              <MarketingForm reload={reloadTable} id={action[1]?.id ?? id} action={action} onClose={() => onCloseAll()} />
            </Tabs.Panel>
            <Tabs.Panel value="orderSettings">
              <></>
            </Tabs.Panel>
          </Tabs>
        </PageLayout>
      </Drawer>
      <Modal title={false} opened={imageOpen[0] === "open"} onClose={() => setImageOpen([])} padding={0} withCloseButton={false} centered>
        <Image src={imageOpen[1]} />
      </Modal>
    </PageLayout>
  );
}

const useHeader = ({
  onClick,
  businessStatus,
  setImageOpen,
  user,
}: {
  onClick: (key: string, record: any) => void;
  businessStatus: any;
  setImageOpen: (e: any) => void;
  user: any;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Лого",
    render: (record) => (
      <Avatar
        style={{ cursor: record?.logo ? "pointer" : "not-allowed" }}
        onClick={() => setImageOpen(["open", record?.logo])}
        src={record?.logo}
        radius="xs"
        component="a">
        <IconPhoto />
      </Avatar>
    ),
  },

  {
    title: "Партнер нэр",
    dataIndex: "partnerName",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.partnerName ?? "-"}
      </Text>
    ),
  },
  {
    title: "Buyer код",
    dataIndex: "registerNo",
    render: (record) => (
      <Text size="sm" w="max-content" c="teal">
        #{record?.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Бизнесийн нэр",
    dataIndex: "profileName",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.profileName ?? "-"}
      </Text>
    ),
  },
  {
    title: "Сектор",
    dataIndex: "bus.name",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.businessSector?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Дэд сектор",
    dataIndex: "subsector",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.businessSubSector?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Үндсэн эсэх",
    dataIndex: "isDefault",
    render: (record) => {
      return record?.isDefault ? (
        <Badge variant="outline" color="green.5">
          Тийм
        </Badge>
      ) : (
        <Badge variant="outline" color="gray.5">
          Үгүй
        </Badge>
      );
    },
  },
  {
    title: "Төлөв",
    dataIndex: "businessStatus",
    render: (record) => (
      <Tag
        color={businessStatus.find((item: IReference) => item?.code === record?.businessStatus)?.color ?? "blue"}
        name={businessStatus.find((item: IReference) => item?.code === record?.businessStatus)?.name ?? record?.businessStatus ?? "-"}
      />
    ),
  },
  {
    title: "Бүртгэсэн",
    dataIndex: "date",
    render: (record) => (
      <Text w="max-content" size="sm">
        {dateTimeFormat(record?.createdAt)}
      </Text>
    ),
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Tooltip label="Засах">
          <ActionIcon
            color="teal"
            variant="light"
            p={0}
            onClick={() => (user.partner.province ? onClick("edit", record) : message.error("Партнер профайл дотроо хаягийн мэдээлэл оруулна уу."))}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Манай худалдан авалт",
  },
];
