import { Button } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useColorScheme } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { ReactComponent as EraLogo } from "../../assets/white-logo.svg";
import { useScreenDetector } from "../screen-detector";

export const RegisterHeader = () => {
  const { classes } = useStyles();
  const { isMobile } = useScreenDetector();

  return (
    <header className={classes.header}>
      <div>
        {!isMobile ? (
          <Link to={"/login"}>
            <div className={classes.row}>
              <EraLogo className={classes.logo} />
              <div className={classes.subTitle}>payment</div>
            </div>
          </Link>
        ) : (
          <Link to={"/login"}>
            <div className={classes.row}>
              <EraLogo className={classes.logo} />
            </div>
          </Link>
        )}
      </div>
      <Button
        onClick={() => {
          window.location.href = "https://dev-cp.dehub.mn/login";
        }}
        variant="gradient"
        gradient={{ from: "red", to: "#051139" }}
        size={isMobile ? "sm" : "md"}>
        Нэвтрэх
      </Button>
    </header>
  );
};

export default RegisterHeader;

const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#00000090",
    height: 70,
    padding: "0 20px",
  },
  logo: {
    height: 50,
    [`@media (max-width: 570px)`]: {
      height: 30,
    },
  },
  subTitle: {
    fontSize: 20,
    lineHeight: "20px",
    fontWeight: 600,
    color: "red",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: "15px",
    alignItems: "end",
  },
  user: {
    padding: theme.spacing.xs,
    color: theme.black,
    "&:hover": {
      backgroundColor: "#ffffff10",
    },
  },
  userActive: {
    backgroundColor: useColorScheme() === "dark" ? theme.colors.dark[8] : theme.white,
  },
}));
