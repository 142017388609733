import { ActionIcon, Avatar, Badge, Box, Button, CloseButton, Divider, Drawer, Flex, Tabs, Text, Tooltip } from "@mantine/core";
import { IconBuilding, IconEdit } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { WarehouseApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { ColumnType, Table } from "../../components/table";
import { WarehouseForm } from "../../components/warehouse/warehouse-form";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { Dialog } from "../../utils/confirm-modal";
import { dateTimeFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export function Warehouse() {
  const breadcrumbs = useBreadcrumb();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<any>([]);
  const tableRef = React.useRef<any>(null);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState<string>("default");
  const { warehouseStatus, zipCodes } = useSelector((state: { general: IGeneral }) => state.general);

  const reloadTable = () => {
    tableRef.current.reload();
  };

  const isDefault = (val: boolean) => {
    switch (val) {
      case true: {
        return (
          <Badge variant="outline" color="green.5">
            Тийм
          </Badge>
        );
      }
      case false: {
        return (
          <Badge variant="outline" color="gray.5">
            Үгүй
          </Badge>
        );
      }
      default: {
        return (
          <Badge color="blue.5" size="md" radius="sm" variant="outline">
            {"-"}
          </Badge>
        );
      }
    }
  };

  const columns = useHeader({
    isDefault,
    warehouseStatus,
    zipCodes,
    loading,
    onClick: (key, record) => {
      switch (key) {
        case "edit":
          setAction(["WAREHOUSE_FORM", record]);
          setDrawerOpen(true);
          return;
        case "remove":
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await WarehouseApi.del(record?.id);
                  message.success("Амжилттай устгагдлаа");
                  setLoading(false);
                  onCloseAll();
                  reloadTable();
                } catch (err: any) {
                  setLoading(false);
                  message.error((err as HttpHandler)?.message!);
                }
                break;
              }
              default:
            }
          });
          break;

        default:
          break;
      }
    },
  });

  const onCloseAll = () => {
    setDrawerOpen(false);
    setAction([]);
    setActiveTab("default");
  };

  return (
    <PageLayout
      title="Манай агуулахууд"
      subTitle="Манай агуулахууд"
      breadcrumb={breadcrumbs}
      extra={[
        <Flex key={1} gap="md" align="center">
          <Button
            onClick={() => {
              setAction([]);
              setDrawerOpen(true);
            }}
            size="sm">
            Агуулах нэмэх
          </Button>
        </Flex>,
      ]}>
      <Box
        sx={(theme) => ({
          background: theme.white,
          borderRadius: 8,
          border: `1px solid ${theme.colors.gray[2]}`,
          padding: "16px 20px",
        })}
        w={"100%"}>
        <Table ref={tableRef} name="warehouse.table" filters={{ query: "" }} columns={columns} loadData={(data) => WarehouseApi.list(data!)} />{" "}
      </Box>

      <Drawer
        styles={{ body: { overflowY: "scroll" } }}
        position="right"
        opened={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        withCloseButton={false}
        padding="xl"
        size="70%">
        <PageLayout extra={[<CloseButton onClick={() => onCloseAll()} key={1} />]} title="Агуулахын мэдээлэл">
          <Tabs variant="pills" value={activeTab} onChange={(value: string | null) => setActiveTab(value ? value : "")}>
            <Tabs.List>
              <Tabs.Tab leftSection={<IconBuilding color={activeTab === "default" ? "white" : "gray"} />} value="default">
                <Text c={activeTab === "default" ? "" : "gray"}>Үндсэн мэдээлэл</Text>
              </Tabs.Tab>
            </Tabs.List>
            <Divider mt="md" mb="md" />
            <Tabs.Panel value="default">
              <WarehouseForm action={action} reload={() => reloadTable()} onClose={() => onCloseAll()} />
            </Tabs.Panel>
          </Tabs>
        </PageLayout>
      </Drawer>
    </PageLayout>
  );
}

const useHeader = ({
  onClick,
  isDefault,
  warehouseStatus,
  zipCodes,
  loading,
}: {
  onClick: (key: string, record: any) => void;
  isDefault: (key: boolean) => JSX.Element;
  warehouseStatus: IReference[];
  zipCodes: any;
  loading: boolean;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },

  {
    title: "Агуулах",
    render: (record) => (
      <Text size="sm" w="max-content" c="teal">
        #{record?.refCode || "-"}
      </Text>
    ),
  },
  {
    title: "Агуулахын нэр",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.name || "-"}
      </Text>
    ),
  },
  {
    title: "Дүүрэг",
    render: (record) => (
      <Text size="sm" w="max-content">
        {zipCodes?.find((item: any) => item.code === record?.district)?.name || "-"}
      </Text>
    ),
  },
  {
    title: "Ажилтан",
    render: (record) =>
      record.warehouseUser ? (
        <Flex align="center" gap={10}>
          <Avatar src={record?.warehouseUser?.avatar} />
          <Text size="sm">
            {record.warehouseUser?.lastName ? record.warehouseUser?.lastName[0] + "." : ""} {record?.warehouseUser?.firstName || "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Утас",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.phone || "-"}
      </Text>
    ),
  },
  {
    title: "Үндсэн эсэх",
    render: (record) => {
      return isDefault(record.isDefault);
    },
  },
  {
    title: "Buyer эсэх",
    render: (record) => {
      return isDefault(record.isBuyer);
    },
  },
  {
    title: "Supplier эсэх",
    render: (record) => {
      return isDefault(record.isSupplier);
    },
  },
  {
    title: "Төлөв",
    render: (record) => (
      <Badge color={record?.warehouseStatus === "ACTIVE" ? "teal" : "red"} size="md" radius="sm" variant="light" styles={{ label: { overflow: "visible" } }}>
        {warehouseStatus?.find((f: IReference) => f.code === record?.warehouseStatus)?.name || "-"}
      </Badge>
    ),
  },
  {
    title: "Бүртгэсэн",
    render: (record) => (
      <Text size="sm" w="max-content">
        {dateTimeFormat(record?.createdAt)}
      </Text>
    ),
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Tooltip label="Засах">
          <ActionIcon loading={loading} variant="light" onClick={() => onClick("edit", record)} color="teal">
            <IconEdit />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Манай агуулахууд",
  },
];
